import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Head from '../components/head';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Stack, Heading, Text, Box, Flex, HStack, Tag, TagLabel, TagRightIcon, chakra } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '@chakra-ui/icons';
import { contentContainer, accentText } from '../constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import getVideoId from 'get-video-id';
import ContactBanner from '../components/contactBanner';
import { kebabCase, serviceUrls } from '../helpers/functions';
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { getSrc } from 'gatsby-plugin-image'
import Button3D from '../components/button3D';
const ButtonD = chakra(Button3D)

const PrevNext = (props) => {
  const { prev, next } = props;
  return (
    <Stack
      isInline={[false, true]}
      spacing={[2, 2, 2, 12]}
      align="center"
      // justify="center"
      justifyContent={prev ? 'space-between' : 'flex-end'}
      m="2em auto"
      flexDirection={['column', 'column', 'column', 'row']}
      maxWidth="70em"
    >
      {prev && (
        <Link to={`/proyectos${prev.fields.slug}`} style={{margin: '12px 0 0 0'}}>
          <ButtonD>
            ←&nbsp;
            <Text isTruncated={[false, true]}>{prev.frontmatter.title}</Text>
          </ButtonD>
        </Link>
      )}
      {next && (
        <Link to={`/proyectos${next.fields.slug}`} style={{margin: '12px 0 0 0'}}>
          <ButtonD>
            <Text isTruncated={[false, true]}>{next.frontmatter.title}</Text>&nbsp;→
          </ButtonD>
        </Link>
      )}
    </Stack>
  );
};
PrevNext.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};

function Proyecto(props) {
  const {
    title,
    description,
    video,
    cliente,
    fecha,
    image,
    date,
    tags,
  } = props.data.mdx.frontmatter;
  const { prev, next, slug } = props.pageContext;

  // TODO use VideoSchema component
  const imageUrl = 'https://visualma.com' + getSrc(image)
  const { id, service } =
    typeof video === 'string' ? getVideoId(video) : { id: null, service: null };
  let embedUrl = null;
  let isoDate = '';

  if (video && id && service === 'vimeo') {
    embedUrl = `https://player.vimeo.com/video/${id}`;
  } else if (service === 'youtube') {
    embedUrl = `https://www.youtube-nocookie.com/embed/${id}`;
  }
  try {
    isoDate = new Date(date).toISOString();
  } catch (e) {
    // console.log('Invalid date for schema', e);
  }

  const videoSchema =
    video && id && embedUrl
      ? JSON.stringify({
          /* eslint-disable */
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name": title,
          "description": description,
          "thumbnailUrl": imageUrl,
          "contentUrl": `https://visualma.com/proyectos${slug}`,
          "embedUrl" : embedUrl,
          "uploadDate": isoDate
        })
      : null;
  
  return (
    <Layout>
      <Head pageTitle={title} imageUrl={imageUrl} pageDescription={description} />
      <div>
        {videoSchema && (
          <Helmet>
            <script type="application/ld+json">{videoSchema}</script>
          </Helmet>
        )}

        {video && (
          <Box background="#000">
            <iframe
              loading="lazy"
              src={`${video}?byline=0&amp;portrait=0&vq=hd1080&modestbranding=1&color=4895b9&iv_load_policy=3&rel=0&mute=1&autoplay=1&muted=true&autoplay=true&playlist=${id}&loop=1&loop=true&dnt=true&pip=true&portrait=false&quality=1080p&responsive=true`}
              title={`Video de ${title}`}
              width="100%"
              height="265"
              frameBorder="0"
              className="headerVideo"
              style={{ height: 'calc(56.25vw - 60px)', width: 'calc(100vw)' }}
              allowFullScreen={true}
            ></iframe>
          </Box>
        )}
        <Box maxWidth="100%" margin="12px auto 1em" padding="0 1.2em">
          <Breadcrumb
            spacing="2px"
            fontSize={['12px', 'md']}
            separator={
              <ChevronRightIcon
                color={accentText}
                d={['none', 'none', 'inline-flex']}
              />
            }
          >
            <BreadcrumbItem d={['none', 'none', 'inline-flex']}>
              <BreadcrumbLink as={Link} to="/">
                Inicio
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              
              <BreadcrumbLink as={Link} to="/proyectos">
                <ChevronLeftIcon
                  color={accentText}
                  d={['inline-flex', 'none', 'none']}
                /> Proyectos
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage d={['none', 'none', 'inline-flex']}>
              <BreadcrumbLink
                style={{ color: '#aaa' }}
                as={Link}
                to={`/proyectos${slug}`}
              >
                {title}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>

        <Heading
          as="h1"
          textAlign="center"
          m="1.3em auto 1.39em auto"
          color="hsl(201 78% 32% / 1)"
          letterSpacing="1px"
          fontSize={["1.45rem", "1.65rem", "1.85rem", "2.05rem"]}
          maxWidth="30em"
        >
          {title}
        </Heading>
        <Box maxWidth="65em" margin="1em auto 2em" padding="0 1.2em">
          <Text letterSpacing="1.5px" lineHeight="26px" fontSize="19.4px">
            {description}
          </Text>
          
          <Flex justifyContent="flex-end" mt={8} color="#4a5568">
            <Flex mr={12}>{cliente}</Flex>
            <Flex>
              <CalendarIcon mt={1} mr={3} /> {fecha}
            </Flex>
          </Flex>
        </Box>
        
        <Container>
          <MDXRenderer>
            {props.data.mdx.body}
          </MDXRenderer>
        </Container>

        <Box maxWidth="920px" m="4em auto" p="0 1em">
          <Text color="gray.500" mb={'16px'}>
            Servicios relacionados:
          </Text>
          <Stack direction={["column", "row"]} spacing={4}>
            {tags.map((tag) => (
              <Tag 
                as={Link}
                to={`${serviceUrls[kebabCase(tag)]}`}
                
                size={'sm'} 
                key={tag} 
                variant="outline" 
                colorScheme="blue"
                justifyContent={'space-between'}
                padding={'8px 20px'}
                _hover={{background: '#3182ce11'}}
                >
                <TagLabel>{tag}</TagLabel>
                <TagRightIcon as={ChevronRightIcon} />
              </Tag>
            ))}
            </Stack>
        </Box>

        <ContactBanner type={5}/>
        
        <PrevNext prev={prev && prev.node} next={next && next.node} />


      </div>
    </Layout>
  );
}
Proyecto.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
  prev: PropTypes.object,
  next: PropTypes.object,
  title: PropTypes.string,
  image: PropTypes.object,
};
const Container = styled.div`

  /*${contentContainer}*/
  /*padding: 6rem 3rem;*/
  font-size: 1.4em;
  line-height: 1.3em;

  h3 {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    margin: 1em 0;
  }
  
  img {
    max-width: 100%;
    margin: 0 auto;
  }
  a {
    color: #2288cc;
  }
  
  
  section {
    padding: 20px 1em 60px 1em;
    overflow: auto;
    zoom: 1;
    max-width: 920px; 
    margin: 0 auto; 
    font-size: 19.4px;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px;
  }
  ${MEDIA.PHONE`
  section {
    font-size: 18px;
  }
  `};
  h4, h2, h3 {
    font-size: 22px;
    font-weight: bold;
    color: #2288cc;
    line-height: 31px;
    text-align: center;
    margin: 1em 0;
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: #2288cc;
    line-height: 1.2;
    text-align: left;
    margin: 1.4em 0 1em;
  }
  h6 {
    font-size: 18px;
    font-weight: bold;
    /* color: #2288cc; */
    line-height: 1.2;
    text-align: left;
    margin: 1.2em 0 0.8em;
  }
  ul {
    list-style: circle;
    padding-left: 24px;
    padding-bottom: 16px;
  }

  .centerSpaced {
    margin: 2em auto 3em;
  }
  .iconList {
    max-width: 80em;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .iconList div {
    /* display: flex; */
    width: 212px;
    flex-basis: 212px;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .iconList iframe {
    /* display: flex; */
    width: 212px;
    flex-basis: 212px;
    flex-grow: 1;
    flex-shrink: 0;
  }
  ${MEDIA.PHONE`
    .iconList div, .iconList p {
      /* display: flex; */
      width: 112px;
      flex-basis: 112px;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .iconList iframe {
      /* display: flex; */
      width: 112px;
      flex-basis: 112px;
      flex-grow: 1;
      flex-shrink: 0;
    }
  `};
  
  .seccion {
    padding: 20px 0 60px 0;
    overflow: auto;
    zoom: 1;
    max-width: 1440px; 
    margin: 0 auto; 
    letter-spacing: 1px;
    font-size: 20px;
    text-rendering: optimizeLegibility;
  }  
  .clearfix {
    overflow: auto;
    zoom: 1;
  }
  .containerSmall, .Complete, .complete {
    padding: 0 1em;
    max-width: 45em;
    margin: 0 auto;
  }
  .containerWide {
    padding: 0 1em;
    max-width: 1200px;
    margin: 40px auto;
  }
  .mitad {
    display: inline;
    float: left;
    width: 47.91666666666667%;
    margin: 0 1.0416666666666665%;
    padding: 0 1em;
  }
  .tercio {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.0416666666666665%;
  }
  .cuarto {
    display: inline;
    float: left;
    width: 22.916666666666664%;
    margin: 0 1.0416666666666665%;
  }
  .dosTercios {
    display: inline;
    float: left;
    width: 64.58333333333334%;
    margin: 0 1.0416666666666665%;
  }
  .parallaxBgBar {
    margin: 0px 0;
  }
  .videoContainer {
    height: 300px;
  }
  ${MEDIA.PHONE`
    padding: 1rem 0rem;
    font-size: 1.3em;
    line-height: 1.2em;
    
    h3 {
      font-size: 28px;
      line-height: 30px;
    }
    .mitad, .tercio, .dosTercios, .cuarto {
      width: 100%;
      float: none;
      display: block;
      margin: 0;
      padding: 0 0.8em;
    }
    .videoContainer {
      height: auto;
    }
  `};
  .fondo-darks {
    background: #222;
    color: #fff;
  }
  .fondoGradienteDark{
    background: #000000;
    background: -moz-linear-gradient(top, #000000 0%, #000000 55%, #3f3f3f 100%);
    background: -webkit-linear-gradient(top, #000000 0%,#000000 55%,#3f3f3f 100%);
    background: linear-gradient(to bottom, #000000 0%,#000000 55%,#3f3f3f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#3f3f3f',GradientType=0 );
    color: white;
  }
  .fondoVerdeLimon{
    background: #769630;
    background: -moz-linear-gradient(top, #769630 0%, #d3cabc 1%, #ffffff 4%, #ffffff 19%, #eaeaea 67%, #eaeaea 67%);
    background: -webkit-linear-gradient(top, #769630 0%,#d3cabc 1%,#ffffff 4%,#ffffff 19%,#eaeaea 67%,#eaeaea 67%);
    background: linear-gradient(to bottom, #769630 0%,#d3cabc 1%,#ffffff 4%,#ffffff 19%,#eaeaea 67%,#eaeaea 67%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#769630', endColorstr='#eaeaea',GradientType=0 );
  }
  .fondoGradienteDarkcomplete{
    background: #000000;
    background: -moz-linear-gradient(top, #000000 0%, #282828 9%, #282828 9%, #000000 22%, #000000 100%);
    background: -webkit-linear-gradient(top, #000000 0%,#282828 9%,#282828 9%,#000000 22%,#000000 100%);
    background: linear-gradient(to bottom, #000000 0%,#282828 9%,#282828 9%,#000000 22%,#000000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 );
    color: white;
  }
`;

export default Proyecto;

export const query = graphql`
  query PostQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        video
        cliente
        fecha
        tags
        image {
          childImageSharp {
            gatsbyImageData(width: 1024, quality: 80)
          }
        }
        date
      }
    }
  }
`;
